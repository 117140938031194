import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import LINEAR_BG from "../assets/compress/linear-bg-primary.webp";
import LAST from "../assets/compress/strategies.webp";

const Strategy = () => {
  // Arbi
  const [isArbiActivate, setIsArbiActivate] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showDone, setShowDone] = useState(false);

  //   Intra
  const [isIntraActivate, setIsIntraActivate] = useState(false);
  const [isIntraActive, setIsIntraActive] = useState(false);
  const [showIntraDone, setShowIntraDone] = useState(false);
  // Triangle
  const [isTriActivate, setIsTriActivate] = useState(false);
  const [isTriActive, setIsTriActive] = useState(false);
  const [showTriDone, setShowTriDone] = useState(false);
  // DCA
  const [isDcaActivate, setIsDcaActivate] = useState(false);
  const [isDcaActive, setIsDcaActive] = useState(false);
  const [showDcaDone, setShowDcaDone] = useState(false);
  // Flash
  const [isFlashActivate, setIsFlashActivate] = useState(false);
  const [isFlashActive, setIsFlashActive] = useState(false);
  const [showFlashDone, setShowFlashDone] = useState(false);
  // Grid Bot
  const [isGridActivate, setIsGridActivate] = useState(false);
  const [isGridActive, setIsGridActive] = useState(false);
  const [showGridDone, setShowGridDone] = useState(false);
  // Future Trading
  const [isFutureActivate, setIsFutureActivate] = useState(false);
  const [isFutureActive, setIsFutureActive] = useState(false);
  const [showFutureDone, setShowFutureDone] = useState(false);

  // Arbi
  const handleActivateClick = () => {
    setIsArbiActivate((prevActivate) => !prevActivate);
    setIsActive((prevActive) => !prevActive);
    setShowDone(true);

    setTimeout(() => {
      setShowDone(false);
      //   console.log("test");
    }, 4000);
  };
  //   Intra
  const handleIntraActivateClick = () => {
    setIsIntraActivate((prevActivate) => !prevActivate);
    setIsIntraActive((prevActive) => !prevActive);
    setShowIntraDone(true);

    setTimeout(() => {
      setShowIntraDone(false);
      //   console.log("test");
    }, 4000);
  };
  // Triangle
  const handleTriActivateClick = () => {
    setIsTriActivate((prevActivate) => !prevActivate);
    setIsTriActive((prevActive) => !prevActive);
    setShowTriDone(true);

    setTimeout(() => {
      setShowTriDone(false);
      //   console.log("test");
    }, 4000);
  };
  // DCA
  const handleDcaActivateClick = () => {
    setIsDcaActivate((prevActivate) => !prevActivate);
    setIsDcaActive((prevActive) => !prevActive);
    setShowDcaDone(true);

    setTimeout(() => {
      setShowDcaDone(false);
      //   console.log("test");
    }, 4000);
  };
  // Flash
  const handleFlashActivateClick = () => {
    setIsFlashActivate((prevActivate) => !prevActivate);
    setIsFlashActive((prevActive) => !prevActive);
    setShowFlashDone(true);

    setTimeout(() => {
      setShowFlashDone(false);
      //   console.log("test");
    }, 4000);
  };
  // Grid Bot
  const handleGridActivateClick = () => {
    setIsGridActivate((prevActivate) => !prevActivate);
    setIsGridActive((prevActive) => !prevActive);
    setShowGridDone(true);

    setTimeout(() => {
      setShowGridDone(false);
      //   console.log("test");
    }, 4000);
  };
  // Grid Bot
  const handleFutureActivateClick = () => {
    setIsFutureActivate((prevActivate) => !prevActivate);
    setIsFutureActive((prevActive) => !prevActive);
    setShowFutureDone(true);

    setTimeout(() => {
      setShowFutureDone(false);
      //   console.log("test");
    }, 4000);
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <div className="container-fluid enzo-wrapper">
        <div className="row">
          <div className="col-lg-2 pad-left-0 max-md-none bor-right max-d-none">
            <Sidebar />
          </div>

          <div className="col-lg-10 pad-left-0 mar-bot">
            <div className="dashboard_content">
              <img src={LINEAR_BG} alt="linear-bg" />
            </div>

            <div className="amt-grid">
              <div className="auto-bot-wrapper profit-mar-top">
                <div className="auto-top profit-top bor-bottom ">
                  <div className="d-flex gap-3 align-items-center">
                    <img
                      src={LAST}
                      alt="balance-history"
                      className="ref-profit-img"
                    />
                    <span className="ref-profit">Strategies</span>
                  </div>
                </div>
                <div className="centralized-wrapper strategy-wrapper">
                  <div className="row w-100 strategy-row">
                    {/* Arbi */}
                    <div className="col-lg-6">
                      <div className="strategy-card-outer card-white">
                        <div className="strategy-card-inner">
                          <div className="strategy-contents">
                            <img
                              src={require("../assets/compress/arbitrage-icon.webp")}
                              alt=""
                              className="strategy-icons"
                            />
                          </div>
                          <div className="strategy-contents w-100">
                            <div className="d-flex justify-content-between align-items-center gap-4">
                              <h5 className="card-inner-title">
                                Arbitrage Strategy
                              </h5>

                              <div>
                                {isArbiActivate ? (
                                  <span className="card-inner-span span-green">
                                    Active
                                  </span>
                                ) : (
                                  <span className="card-inner-span span-red">
                                    Inactive
                                  </span>
                                )}
                              </div>
                            </div>

                            <p className="card-inner-content mt-2">
                              Activate the strategies using the activate button{" "}
                            </p>

                            <div
                              className={`strategy-loader ${
                                isActive ? "active" : ""
                              }`}
                            ></div>

                            <div className="strategy-btn-wrapper ">
                              {!isArbiActivate ? (
                                <span className="activate-btn-content">
                                  Currently Inactive
                                </span>
                              ) : (
                                showDone && (
                                  <span
                                    className={
                                      "activate-btn-content span-green"
                                    }
                                  >
                                    Done!
                                  </span>
                                )
                              )}

                              <button
                                onClick={handleActivateClick}
                                className={`${
                                  !isArbiActivate
                                    ? "activate-btn activate-bg"
                                    : "activate-btn deactivate-bg"
                                }`}
                              >
                                {!isArbiActivate ? (
                                  <span>Activate</span>
                                ) : (
                                  <span>De-Activate</span>
                                )}
                              </button>
                            </div>
                          </div>
                          {/* gradient img */}
                          <img
                            src={require("../assets/compress/arbitrage-blur.webp")}
                            alt="arbitrage"
                            className="card-grad-img"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Intra */}
                    <div className="col-lg-6">
                      <div className="strategy-card-outer card-blue">
                        <div className="strategy-card-inner">
                          <div className="strategy-contents">
                            <img
                              src={require("../assets/compress/indra-icon.webp")}
                              alt=""
                              className="strategy-icons"
                            />
                          </div>

                          <div className="strategy-contents w-100">
                            <div className="d-flex justify-content-between align-items-center gap-4">
                              <h5 className="card-inner-title">
                                Intra Strategy
                              </h5>

                              <div>
                                {isIntraActivate ? (
                                  <span className="card-inner-span span-green">
                                    Active
                                  </span>
                                ) : (
                                  <span className="card-inner-span span-red">
                                    Inactive
                                  </span>
                                )}
                              </div>
                            </div>

                            <p className="card-inner-content mt-2">
                              Activate the strategies using the activate button{" "}
                            </p>

                            <div
                              className={`strategy-loader blue ${
                                isIntraActive ? "active" : ""
                              }`}
                            ></div>

                            <div className="strategy-btn-wrapper ">
                              {!isIntraActivate ? (
                                <span className="activate-btn-content">
                                  Currently Inactive
                                </span>
                              ) : (
                                showIntraDone && (
                                  <span
                                    className={
                                      "activate-btn-content span-green"
                                    }
                                  >
                                    Done!
                                  </span>
                                )
                              )}

                              <button
                                onClick={handleIntraActivateClick}
                                className={`${
                                  !isIntraActivate
                                    ? "activate-btn activate-bg"
                                    : "activate-btn deactivate-bg"
                                }`}
                              >
                                {!isIntraActivate ? (
                                  <span>Activate</span>
                                ) : (
                                  <span>De-Activate</span>
                                )}
                              </button>
                            </div>
                          </div>
                          {/* gradient img */}
                          <img
                            src={require("../assets/compress/intra-blur.webp")}
                            alt="arbitrage"
                            className="card-grad-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row w-100 strategy-row">
                    {/* Triangle */}
                    <div className="col-lg-6">
                      <div className="strategy-card-outer card-green">
                        <div className="strategy-card-inner">
                          <div className="strategy-contents">
                            <img
                              src={require("../assets/compress/triangle-icon.webp")}
                              alt=""
                              className="strategy-icons"
                            />
                          </div>
                          <div className="strategy-contents w-100">
                            <div className="d-flex justify-content-between align-items-center gap-4">
                              <h5 className="card-inner-title">
                                Triangle Strategy
                              </h5>

                              <div>
                                {isTriActivate ? (
                                  <span className="card-inner-span span-green">
                                    Active
                                  </span>
                                ) : (
                                  <span className="card-inner-span span-red">
                                    Inactive
                                  </span>
                                )}
                              </div>
                            </div>

                            <p className="card-inner-content mt-2">
                              Activate the strategies using the activate button{" "}
                            </p>

                            {/* <div className="strategy-loader"></div> */}
                            <div
                              className={`strategy-loader green ${
                                isTriActive ? "active" : ""
                              }`}
                            ></div>

                            <div className="strategy-btn-wrapper ">
                              {!isTriActivate ? (
                                <span className="activate-btn-content">
                                  Currently Inactive
                                </span>
                              ) : (
                                showTriDone && (
                                  <span
                                    className={
                                      "activate-btn-content span-green"
                                    }
                                  >
                                    Done!
                                  </span>
                                )
                              )}

                              <button
                                onClick={handleTriActivateClick}
                                className={`${
                                  !isTriActivate
                                    ? "activate-btn activate-bg"
                                    : "activate-btn deactivate-bg"
                                }`}
                              >
                                {!isTriActivate ? (
                                  <span>Activate</span>
                                ) : (
                                  <span>De-Activate</span>
                                )}
                              </button>
                            </div>
                          </div>
                          {/* gradient img */}
                          <img
                            src={require("../assets/compress/triangle-blur.webp")}
                            alt="arbitrage"
                            className="card-grad-img"
                          />
                        </div>
                      </div>
                    </div>
                    {/* DCA */}
                    <div className="col-lg-6">
                      <div className="strategy-card-outer card-violet">
                        <div className="strategy-card-inner">
                          <div className="strategy-contents">
                            <img
                              src={require("../assets/compress/dca-icon.webp")}
                              alt=""
                              className="strategy-icons"
                            />
                          </div>
                          <div className="strategy-contents w-100">
                            <div className="d-flex justify-content-between align-items-center gap-4">
                              <h5 className="card-inner-title">DCA Strategy</h5>

                              <div>
                                {isDcaActivate ? (
                                  <span className="card-inner-span span-green">
                                    Active
                                  </span>
                                ) : (
                                  <span className="card-inner-span span-red">
                                    Inactive
                                  </span>
                                )}
                              </div>
                            </div>

                            <p className="card-inner-content mt-2">
                              Activate the strategies using the activate button{" "}
                            </p>

                            {/* <div className="strategy-loader"></div> */}
                            <div
                              className={`strategy-loader violet ${
                                isDcaActive ? "active" : ""
                              }`}
                            ></div>

                            <div className="strategy-btn-wrapper ">
                              {!isDcaActivate ? (
                                <span className="activate-btn-content">
                                  Currently Inactive
                                </span>
                              ) : (
                                showDcaDone && (
                                  <span
                                    className={
                                      "activate-btn-content span-green"
                                    }
                                  >
                                    Done!
                                  </span>
                                )
                              )}

                              <button
                                onClick={handleDcaActivateClick}
                                className={`${
                                  !isDcaActivate
                                    ? "activate-btn activate-bg"
                                    : "activate-btn deactivate-bg"
                                }`}
                              >
                                {!isDcaActivate ? (
                                  <span>Activate</span>
                                ) : (
                                  <span>De-Activate</span>
                                )}
                              </button>
                            </div>
                          </div>
                          {/* gradient img */}
                          <img
                            src={require("../assets/compress/dca-blur.webp")}
                            alt="arbitrage"
                            className="card-grad-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row w-100 strategy-row">
                    {/* Flash */}
                    <div className="col-lg-6">
                      <div className="strategy-card-outer card-yellow">
                        <div className="strategy-card-inner">
                          <div className="strategy-contents">
                            <img
                              src={require("../assets/compress/flash-icon.webp")}
                              alt=""
                              className="strategy-icons"
                            />
                          </div>
                          <div className="strategy-contents w-100">
                            <div className="d-flex justify-content-between align-items-center gap-4">
                              <h5 className="card-inner-title">Flash Loan</h5>

                              <div>
                                {isFlashActivate ? (
                                  <span className="card-inner-span span-green">
                                    Active
                                  </span>
                                ) : (
                                  <span className="card-inner-span span-red">
                                    Inactive
                                  </span>
                                )}
                              </div>
                            </div>

                            <p className="card-inner-content mt-2">
                              Activate the strategies using the activate button{" "}
                            </p>

                            {/* <div className="strategy-loader"></div> */}
                            <div
                              className={`strategy-loader yellow ${
                                isFlashActive ? "active" : ""
                              }`}
                            ></div>

                            <div className="strategy-btn-wrapper ">
                              {!isFlashActivate ? (
                                <span className="activate-btn-content">
                                  Currently Inactive
                                </span>
                              ) : (
                                showFlashDone && (
                                  <span
                                    className={
                                      "activate-btn-content span-green"
                                    }
                                  >
                                    Done!
                                  </span>
                                )
                              )}

                              <button
                                onClick={handleFlashActivateClick}
                                className={`${
                                  !isFlashActivate
                                    ? "activate-btn activate-bg"
                                    : "activate-btn deactivate-bg"
                                }`}
                              >
                                {!isFlashActivate ? (
                                  <span>Activate</span>
                                ) : (
                                  <span>De-Activate</span>
                                )}
                              </button>
                            </div>
                          </div>
                          {/* gradient img */}
                          <img
                            src={require("../assets/compress/flash-blur.webp")}
                            alt="arbitrage"
                            className="card-grad-img"
                          />
                        </div>
                      </div>
                    </div>
                    {/* Grid bot */}
                    <div className="col-lg-6">
                      <div className="strategy-card-outer card-skyblue">
                        <div className="strategy-card-inner">
                          <div className="strategy-contents">
                            <img
                              src={require("../assets/compress/bot-icon.webp")}
                              alt=""
                              className="strategy-icons"
                            />
                          </div>
                          <div className="strategy-contents w-100">
                            <div className="d-flex justify-content-between align-items-center gap-4">
                              <h5 className="card-inner-title">Grid Bot</h5>

                              <div>
                                {isGridActivate ? (
                                  <span className="card-inner-span span-green">
                                    Active
                                  </span>
                                ) : (
                                  <span className="card-inner-span span-red">
                                    Inactive
                                  </span>
                                )}
                              </div>
                            </div>

                            <p className="card-inner-content mt-2">
                              Activate the strategies using the activate button{" "}
                            </p>

                            {/* <div className="strategy-loader"></div> */}
                            <div
                              className={`strategy-loader skyblue ${
                                isGridActive ? "active" : ""
                              }`}
                            ></div>

                            <div className="strategy-btn-wrapper ">
                              {!isGridActivate ? (
                                <span className="activate-btn-content">
                                  Currently Inactive
                                </span>
                              ) : (
                                showGridDone && (
                                  <span
                                    className={
                                      "activate-btn-content span-green"
                                    }
                                  >
                                    Done!
                                  </span>
                                )
                              )}

                              <button
                                onClick={handleGridActivateClick}
                                className={`${
                                  !isGridActivate
                                    ? "activate-btn activate-bg"
                                    : "activate-btn deactivate-bg"
                                }`}
                              >
                                {!isGridActivate ? (
                                  <span>Activate</span>
                                ) : (
                                  <span>De-Activate</span>
                                )}
                              </button>
                            </div>
                          </div>
                          {/* gradient img */}
                          <img
                            src={require("../assets/compress/bot-blur.webp")}
                            alt="arbitrage"
                            className="card-grad-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row w-100 strategy-row">
                    {/* Future Trading */}
                    <div className="col-lg-6">
                      <div className="strategy-card-outer card-orange">
                        <div className="strategy-card-inner">
                          <div className="strategy-contents">
                            <img
                              src={require("../assets/compress/future-icon.webp")}
                              alt=""
                              className="strategy-icons"
                            />
                          </div>
                          <div className="strategy-contents w-100">
                            <div className="d-flex justify-content-between align-items-center gap-4">
                              <h5 className="card-inner-title">
                                Future Trading
                              </h5>

                              <div>
                                {isFutureActivate ? (
                                  <span className="card-inner-span span-green">
                                    Active
                                  </span>
                                ) : (
                                  <span className="card-inner-span span-red">
                                    Inactive
                                  </span>
                                )}
                              </div>
                            </div>

                            <p className="card-inner-content mt-2">
                              Activate the strategies using the activate button{" "}
                            </p>

                            {/* <div className="strategy-loader"></div> */}
                            <div
                              className={`strategy-loader orange ${
                                isFutureActive ? "active" : ""
                              }`}
                            ></div>

                            <div className="strategy-btn-wrapper ">
                              {!isFutureActivate ? (
                                <span className="activate-btn-content">
                                  Currently Inactive
                                </span>
                              ) : (
                                showFutureDone && (
                                  <span
                                    className={
                                      "activate-btn-content span-green"
                                    }
                                  >
                                    Done!
                                  </span>
                                )
                              )}

                              <button
                                onClick={handleFutureActivateClick}
                                className={`${
                                  !isFutureActivate
                                    ? "activate-btn activate-bg"
                                    : "activate-btn deactivate-bg"
                                }`}
                              >
                                {!isFutureActivate ? (
                                  <span>Activate</span>
                                ) : (
                                  <span>De-Activate</span>
                                )}
                              </button>
                            </div>
                          </div>
                          {/* gradient img */}
                          <img
                            src={require("../assets/compress/future-blur.webp")}
                            alt="arbitrage"
                            className="card-grad-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Strategy;
